import { gsap } from "gsap";
import { ScrollTrigger } from "gsap/ScrollTrigger.js";

gsap.registerPlugin(ScrollTrigger);

if ($('.vertical-card-slider').length) {
  document.addEventListener('DOMContentLoaded', function() {
    let vcsmm = gsap.matchMedia();
    let vcsHeight = $('.vertical-card-slider');

    vcsmm.add("(min-width: 768px)", () => {
        ScrollTrigger.create({
          trigger: ".vertical-card-slider",
          start: "top 100px",
          end: () => "+=" + (vcsHeight.height() - 100),
          pin: ".vertical-card-slider-heading",
          scrub: true,
          pinSpacing: false,
          markers: false,
          invalidateOnRefresh: true,
        });
    });
  }, false)
}

$(window).on('load', function() {
  ScrollTrigger.refresh(true);
});