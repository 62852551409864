import { gsap } from "gsap";
import { ScrollTrigger } from "gsap/ScrollTrigger.js";

gsap.registerPlugin(ScrollTrigger);

const news = document.querySelector(".news-container");

if (news) {

	function getScrollAmount() {
		let newsWidth = news.scrollWidth;
		return -(newsWidth - window.innerWidth);
	}

	const tween = gsap.to(news, {
		x: getScrollAmount,
		duration: 3,
		delay: .5,
		ease: "none",
	});


	ScrollTrigger.create({
		trigger:".in-the-news",
		start:"top top",
		end: () => `+=${getScrollAmount() * -1}`,
		pin:true,
		animation:tween,
		scrub:1,
		invalidateOnRefresh:true,
		markers:false
	});

}

