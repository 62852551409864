import Swiper from 'swiper/bundle';
import 'swiper/css/bundle';

if ($('.home-hero-swiper')) {

	let buttonAction = function() {
		if ($(window).width() > 768) {
			$(".hero-buttons").css('display','grid')
			$(".home-hero").css('display','grid')
			$(".home-hero-swiper").hide()
			$(".hero-button").each(function() {
				$(this).on('mouseover', function() {
					$(".hero-slide:not(#" + $(this).data('slide')).removeClass('show');
					$("#" + $(this).data('slide')).addClass('show');
					$('.hero-button').removeClass('active');
					$(this).addClass('active');
				});
			});
		} else {
			$(".hero-buttons").hide()
			$(".home-hero").hide()
			$(".home-hero-swiper").show();
		}
	}

	$(document).ready(function(){
		buttonAction();
	});

	$(window).resize(function() {
		buttonAction();
	});

	const homePageSwiper = new Swiper('.home-hero-swiper', {
		pagination: {
			clickable: true,
			el: ".swiper-pagination",
		},
	});
}
