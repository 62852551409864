import Headroom from "headroom.js";
import Lenis from '@studio-freight/lenis';

const lenis = new Lenis({
    duration: 1.2,
		easing: (t) => Math.min(1, 1.001 - Math.pow(2, -10 * t)),
		smoothWheel: true,
    smoothTouch: false,
    touchMultiplier: 2,
    wheelMultiplier: 1
})

function raf(time) {
  lenis.raf(time)
  requestAnimationFrame(raf)
}

requestAnimationFrame(raf)

// select your header or whatever element you wish
const header = document.querySelector("#header");

const headroom = new Headroom(header, { offset: 1100 });
headroom.init();

const hasChildrenElements = document.querySelectorAll('.nav-parent.has-children');
const overlay = document.getElementById('overlay');

var delay = 1000;
setTimeout(function() {

	if ($(window).width() > 1028) {
		$('.nav-dropdown').show();
	}

	hasChildrenElements.forEach(element => {
		element.addEventListener('mouseenter', () => {
			if ($(window).width() > 1028) {
				overlay.style.opacity = '1';
				overlay.style.zIndex = '200';
				header.classList.add('open');
				element.classList.add('open');
			}
		});
		
		element.addEventListener('mouseleave', () => {
			if ($(window).width() > 1028) {
				overlay.style.opacity = '0';
				overlay.style.zIndex = '-999';
				header.classList.remove('open');
				element.classList.remove('open');
			}
		});

		element.addEventListener('click', () => {
			if ($(window).width() > 1028) {
				overlay.style.opacity = '0';
				overlay.style.zIndex = '-999';
				header.classList.remove('open');
				element.classList.remove('open');
			}
		});
	});
}, delay);

$(document).scroll(function() {
    if ($(document).scrollTop() > 800) {
    	if (!(header.classList.contains('mini'))) {
    		header.classList.add('mini');
    	}
    } else {
    	if (header.classList.contains('mini')) {
    		header.classList.remove('mini');
    	}
    }
})


$('.hamburger').on('click', function() {
	$(this).toggleClass('open');
	
  if (header.classList.contains('open')) {
    lenis.start();
    header.classList.remove('open');
  } else {
    lenis.stop();
    header.classList.add('open');
    header.classList.remove('mini');
    header.classList.remove('mini');
  }
});

$('.nav-item').on('click', function(e) {
	if ($(window).width() <= 1028) {
		let nodeId = $(this).data('node');
		if (nodeId == 13) {
			$('#locations').toggleClass('open');
		} else if ($(this).hasClass('has-children')) {
			$('#nav-item-' + nodeId).toggleClass('open');
		}
		$(this).toggleClass('open');
	}
});


$(window).resize(function() {
	if ($(window).width() > 1028) {
		lenis.start();
		header.classList.remove('open');
		$('.hamburger').removeClass('open');

		if ($(window).width() > 1028) {
			$('.nav-dropdown').show();
		}
	}
});