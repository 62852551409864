import { gsap } from "gsap";
import { ScrollTrigger } from "gsap/ScrollTrigger.js";

gsap.registerPlugin(ScrollTrigger);

const quants = document.querySelector(".quants");


if (quants) {

    function animateQuants() {
        $('.quant:not(.animated)').each(function () {
            $(this).prop('Counter',0).animate({
                Counter: $(this).data('number')
            }, {
                duration: 1000,
                easing: 'swing',
                step: function (now) {
                    $(this).text(Math.ceil(now).toLocaleString('en'));
                }
            });

            $(this).addClass('animated');
        });
    }

    ScrollTrigger.create({
        trigger:".quants",
        start:"top bottom",
        onEnter: animateQuants,
        scrub:1,
        markers:false,
        invalidateOnRefresh: true
    });
}
