$(document).ready(function() {
	if ($('table thead th')) {
		let headerArray = [];
		$('table thead th').each(function(i) {
			headerArray.push($(this).text());
		});


		$('table tbody tr').each(function() {
			$(this).children('td').each(function(i) {
				console.log(headerArray[i]);
				$(this).attr('data-th', headerArray[i]);
			});
		});
	}
});