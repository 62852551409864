import Swiper from 'swiper/bundle';
import 'swiper/css/bundle';

if ($('.featured-deployments-section')) {

	const featuredDeploymentsSwiper = new Swiper('.featured-deployments-section .swiper', {});

	$('.swiper-nav-item').on('click', function() {
		featuredDeploymentsSwiper.slideTo($(this).data('index'), 300, true);
		$('.swiper-nav-item').not($(this)).removeClass('active');
		$(this).addClass('active');
	});

	featuredDeploymentsSwiper.on('slideChange', function () {
	  console.log(featuredDeploymentsSwiper.activeIndex);
	  $('.swiper-nav-item').removeClass('active');
	  $('.swiper-nav .swiper-nav-item').eq(featuredDeploymentsSwiper.activeIndex).addClass('active');
	  $('.nav-titles').html($('.swiper-nav .swiper-nav-item').eq(featuredDeploymentsSwiper.activeIndex).html());
	  console.log($('.swiper-nav .swiper-nav-item').eq(featuredDeploymentsSwiper.activeIndex).html());
	});
}




