const overlay = document.getElementById('overlay');
const teamMembers = document.querySelectorAll('.team-member');

if (teamMembers.length) {
	teamMembers.forEach(element => {
		element.addEventListener('click', () => {
			overlay.style.opacity = '1';
			overlay.style.zIndex = '2000';
			$('#' + element.dataset.modal).css('display', 'grid');
		});
	});

	overlay.addEventListener('click', () => {
		overlay.style.opacity = '0';
		overlay.style.zIndex = '-999';
		$('.team-member-modal').hide();
	});

	$('.x').on('click', function() {
		$('.team-member-modal').hide();
		overlay.style.opacity = '0';
		overlay.style.zIndex = '-999';
	});
};